import './App.css';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Link } from "react-router-dom";

const imgCount = 8;
// console.log(window.location.pathname)

function Home(data) {
    let slides = [];
    for(let i = 0; i < imgCount; i++) {
        slides.push(
            <SwiperSlide>
                <img src={`/leckoImages/lecko${i+1}.webp`} className='leckoImage' alt="lecko" />
            </SwiperSlide>
        );
    }
    return (
        <div id="home">
            <h1 id='greeting'>Hello!</h1>
            <p>I'm 22 years old streamer from Europe &#x1F1EA;&#x1F1FA;, Latvia &#x1F1F1;&#x1F1FB;, my dream is to become full-time streamer! Feel free to follow <a href="#SOCLIST">my social media's right here</a></p>
            <Swiper
                modules={[Autoplay]}
                autoplay={{ delay: 3000 }}
                spaceBetween={100}
                slidesPerView={1}
            >
                {slides}
            </Swiper>
            <p id='p2'>Buying merch is a great way to support my journey to become a full time stramer!<br/>Please visit my store.</p>
            <Link to={'/store'} id='GoToStore' onClick={data.openStore}>
                Go to store
            </Link>
        </div>
    )
}

export default Home;