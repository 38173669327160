import { useState } from 'react';
import { motion } from 'framer-motion';
import Store from './Store';
import Home from './home';
import Navbar from './navbar';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

function App() {
  const [opened, setOpened] = useState(false);
  const toggleList = () => setOpened(!opened);

  return (
    <BrowserRouter>
      <Navbar 
        opened={opened}
        routes={[
          {
            name: "store",
            path: "/store"
          },
          {
            name: "home",
            path: "/"
          }
        ]}
        toggleList={toggleList}
      />
      <Routes>
        <Route
          path="/store"
          element={
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
            >
              <Store />
            </motion.div>
          }
        />
        <Route
          path="/"
          element={<Home openStore={() => { setOpened(false); }} />}
        />
        <Route 
          path='/purchase-success'
          element={
            <main id='purchase_status'>
              <h1>
                The purchase was successfull!
              </h1>
              <p>
                Thank you for your support. 
              </p>
              <Link to={'/'}>
                Back to home
              </Link>
            </main>
          }
        />
        <Route 
          path='/purchase-failed'
          element={
            <main id='purchase_status'>
              <h1>
                The purchase has failed!
              </h1>
              <p>
                This could be related to your payment provider declining. 
              </p>
              <Link to={'/'}>
                Back to home
              </Link>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;