import "./App.css";
import { useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import data from "./products.json";
import defaults from "./default_images.json";
import Variants from "./variants";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect } from "react";

const location = await fetch("https://freeipapi.com/api/json");
const locationData = await location.json();

console.log(locationData);

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark overlay
    width: "100%",
    height: "100%",
    zIndex: 2,
  },
  content: {
    backgroundColor: "#333", // Dark background for the modal content
    color: "#fff", // White text color
    border: "2px solid #8080ff", // Remove default border
    borderRadius: "10px", // Add border radius
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add shadow
    top: "50%", // Center the modal vertically
    left: "50%", // Center the modal horizontally
    transform: "translate(-50%, -50%)", // Translate to center
    maxWidth: "100%", // Limit maximum width of the modal
    width: "80%",
    height: "85%",
  },
};

function Product(data) {
  const productData = data.data;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentVariant, setCurrentVariant] = useState(0);

  let optionDict = {};
  Object.keys(productData.options).forEach(
    (elem) => (optionDict[elem] = productData.variants[0][elem]),
  );

  const [options, setOptions] = useState(optionDict);

  const changeOption = (optionName, optionValue) => {
    const copy = { ...options };
    copy[optionName] = optionValue;

    setOptions(copy);
  };



  const toggleModal = () => setModalOpen(!modalOpen);
  const updateVariant = (val) => setCurrentVariant(val);

  useEffect(() => {
    console.log("Taking effect");

    const keys = Object.keys(options);
    for (
      let variantIndex = 0;
      variantIndex < productData.variants.length;
      variantIndex++
    ) {
      let failed = false;

      for (let optionIndex = 0; optionIndex < keys.length; optionIndex++) {
        if (
          options[keys[optionIndex]] !==
          productData.variants[variantIndex][keys[optionIndex]]
        ) {
          failed = true;
          break;
        }
      }

      if (!failed) {
        updateVariant(variantIndex);
        console.log("Setting variant to ", variantIndex);
        break;
      }
    }

    console.log(currentVariant);
  }, [options, productData, currentVariant]);

  const buy = () => {
    console.log('called buy')
    fetch(`/.netlify/functions/paymentForm?productId=${productData.id}&variantId=${productData.variants[currentVariant].id}&variantId2=${productData.variants[currentVariant].variant_id}&cc=${locationData.countryCode}`)
      .then(data => data.json())
      .then(data => {
        // setUserSecret(data['client_secret']);
        window.location.href = data.paymentUrl
      })
  }

  return (
    <li className="product">
      <img
        src={`/${productData.id}/${productData.variants[currentVariant].images[defaults[productData.id] === undefined ? 0 : defaults[productData.id] - 1]}`}
        alt="Product"
        className="frontImage"
      />
      <div className="propContainer">
        <p>{productData.variants[currentVariant].name}</p>
        <p>{productData.variants[currentVariant].price}€</p>
      </div>
      <div>
        <button onClick={toggleModal}>View</button>
        <button onClick={buy}>Buy</button>
      </div>
      <Modal isOpen={modalOpen} style={customStyles}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.4 }}
        >
          <header className="modalHeader">
            <span className="productTitle">
              {productData.variants[currentVariant].name}
            </span>
            <svg
              onClick={toggleModal}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </header>
          <main className="modalMain">
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
            >
              {productData.variants[currentVariant].images.map((elem) => (
                <SwiperSlide>
                  <img
                    src={`/${productData.id}/${elem}`}
                    alt="store product"
                    className="carouselImage"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <h1>{productData.variants[currentVariant].name}</h1>
            <button onClick={buy} id="buy">Buy</button>
            <div>{productData.variants[currentVariant].price} €</div>
            <ul id="OPTS">
              {Object.keys(productData.options).map((elem) => (
                <Variants
                  data={{
                    optionName: elem,
                    optionValues: productData.options[elem],
                  }}
                  setValue={changeOption}
                  value={options}
                />
              ))}
            </ul>
          </main>
        </motion.div>
      </Modal>
    </li>
  );
}

// let products = data.data.map((elem) => <Product images={elem.images} data={elem} title={elem.title} desc={elem.description} variants={elem.variants} />);

// console.log(products);

function Store() {
  return (
    <ul className="productContainer">
      {data.map((elem) => (
        <Product data={elem} />
      ))}
    </ul>
  );
}

export default Store;
