import './App.css'
import Links from './links';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";


function Navbar({ routes, opened, toggleList }) {
    return (
        <nav>
        <Link to={'/'}>
            <img src="/lecko.gif" alt="lecko logo" id="logo"/>
            <h1 id='title_text'><snan>Lecko</snan></h1>
        </Link>
        { !opened ?
        <svg onClick={toggleList} className="listicon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5"/>
        </svg>:<svg className="listicon" onClick={toggleList} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18 17.94 6M18 18 6.06 6"/>
        </svg>
        }
        { opened && (
        <motion.div 
            id='mobileListView'
            initial={{width: 0}}
            animate={{width: '70%'}}
        >
            <motion.ul initial={{opacity: 0}} animate={{opacity: 1}} id='verticalLinkList'>
                <div id='sidebarLinks'>
                    {
                        routes.map((elem) => <li>
                            <Link to={elem.path} onClick={() => {toggleList();}}>
                                {elem.name}
                            </Link>
                        </li>)
                    }
                </div>
                <Links showName='true' />
            </motion.ul>
        </motion.div>)}
        <ul id='largeNavLocationList'>
            {
                routes.map((elem) => <li>
                    <Link to={elem.path}>
                        {elem.name}
                    </Link>
                </li>)
            }
        </ul>
        </nav> 
    )
}

export default Navbar;