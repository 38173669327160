import "./App.css";

function Variants({ data, setValue, value }) {
  return (
    <div>
      <h3>{data.optionName}</h3>
      <ul className="List">
        {data.optionValues.map((elem) => (
          <li
            className={value[data.optionName] === elem ? "active" : ""}
            onClick={() => setValue(data.optionName, elem)}
          >
            {elem}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Variants;
