import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Links from './links';

const root = ReactDOM.createRoot(document.getElementById('root'));
const soclinks = ReactDOM.createRoot(document.getElementById('socials'));

root.render(
  <App />
);

soclinks.render(
  <ul id='SOCLIST'>
    <Links showName='true'/>
  </ul>
)


